import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/user'
  },
  {
    path: '/auth/:code?/:state?',
    name: 'auth',
    component: () => import('./view/user/auth'),
    meta: {
      title: '驿车驹',

    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('./view/user'),
    meta: {
      title: '驿车驹',
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }, {
    path: '/information',
    name: 'information',
    component: () => import('./view/user/information'),
    meta: {
      title: '统计'
    }
  },{
    path: '/newindex',
    name: 'newindex',
    component: () => import('./view/user/newindex'),
    meta: {
      title: '驿车驹',
      requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }, {
    path: '/information_qx',
    name: 'information_qx',
    component: () => import('./view/user/information_qx'),
    meta: {
      title: '统计'
    }
  }, {
    path: '/visit',
    name: 'visit',
    component: () => import('./view/user/visit'),
    meta: {
      title: '访问'
    }
  }, {
    path: '/statistics',
    name: 'statistics',
    component: () => import('./view/user/statistics'),
    meta: {
      title: '活动统计'
    }
  }, {
    path: '/erweima',
    name: 'erweima',
    component: () => import('./view/user/erweima'),
    meta: {
      title: '驿车驹'
    }
  }, {
    path: '/standings',
    name: 'standings',
    component: () => import('./view/user/standings'),
    meta: {
      title: '我的战绩'
    }
  }, {
    path: '/commission',
    name: 'commission',
    component: () => import('./view/user/commission'),
    meta: {
      title: '驿车驹'
    }
  }, {
    path: '/commission_xq',
    name: 'commission_xq',
    component: () => import('./view/user/commission_xq'),
    meta: {
      title: '驿车驹'
    }
  }, {
    path: '/activity',
    name: 'activity',
    component: () => import('./view/activity'),
    meta: {
      title: '驿车驹',
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }, {
    path: '/indexmian',
    name: 'indexmian',
    component: () => import('./view/activity/indexmian'),
    meta: {
      title: '驿车驹'
    }
  }, {
    path: '/manage',
    name: 'manage',
    component: () => import('./view/user/manage'),
    meta: {
      title: '驿车驹'
    }
  },{
    path: '/apply',
    name: 'apply',
    component: () => import('./view/apply'),
    meta: {
      title: '【驿车驹】引流·锁客·练团队·定制专属活动方案',
      requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }, {
    path: '/verification',
    name: 'verification',
    component: () => import('./view/verification'),
    meta: {
      title: '手动核销',
    }
  }, {
    path: '/activity_b',
    name: 'activity_b',
    component: () => import('./view/activity_b'),
    meta: {
      title: '驿车驹',
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }, {
    path: '/list',
    name: 'list',
    component: () => import('./view/activity_b/list'),
    meta: {
      title: '驿车驹',
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }, {
    path: '/package',
    name: 'package',
    component: () => import('./view/h5/package'),
    meta: {
      title: '驿车驹',
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    }
  }




]
let wx = require('weixin-js-sdk')
// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '')
})

const RouterConfig = {
  mode: 'history',
  routes: routes
}

const router = new Router(RouterConfig)

router.beforeEach((to, from, next) => {
  let userAgent = navigator.userAgent
  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    sessionStorage.setItem('originUrl', location.href)  // 用于ios分享
  }  
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  const token = localStorage.getItem('hdtoken')
  if (to.meta.requireAuth) {
    if (localStorage.getItem('hdtoken')) { //判断本地是否存在access_token
      next();
    } else {
      if (!token && to.name !== 'auth') {
        //保存当前路由地址，授权后还会跳到此地址
        sessionStorage.setItem('beforeUrl', to.fullPath)
        sessionStorage.setItem('tochengong', to.fullPath)
        var appid = "wx406b62706ded5aa8"
               // const redirect_uri = encodeURIComponent("http://localhost:8080/auth");
        // //  授权请求, 并跳转
        const redirect_uri = encodeURIComponent("https://marketing.car-posthouse.cn/auth");

 
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }
    }
  }




  let mark = to.fullPath.indexOf('code')
  // let mark = to.fullPath.indexOf('aid')
  // console.log(mark)
  // console.log(to.name)
  console.log(to.fullPath)
  // console.log(from.name)
  if (token) {

    if (from.name === 'user' && to.name !== 'information' && to.name !== 'erweima' && to.name !== 'standings' && to.name !== 'information_qx' && to.name !== 'visit') {
      console.log('关闭')
      WeixinJSBridge.call('closeWindow');
    }
  }


  // if (token && to.name === 'auth') {
  //   // return next({ name: 'user' })
  //   WeixinJSBridge.call('closeWindow');
  // }

  if (!window.initUrl) {
    window.initUrl = location.href.split('#')[0]
  }
  next()
})

export {
  router
}
