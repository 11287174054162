import Vue from 'vue';
import App from './App';
import { router } from './router';
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/css/style.css'
import '@/assets/css/JDZhengHT-Bold.css';
import '@/assets/css/MFBanHei_Noncommercial-Regular.css';
import './assets/css/FZZCHJW-GB.css';
import './assets/css/baseStyle.css'
import wx from 'weixin-js-sdk'
import  'amfe-flexible'
import LicenseKeyboard from 'vue-license-keyboard';
import 'vue-license-keyboard/lib/vue-license-keyboard.css';
Vue.use(LicenseKeyboard);
// import 'lib-flexible/flexible'
Vue.prototype.wx = wx

Vue.use(Vant);


new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
